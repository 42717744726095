@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


/* Tło inspirowane klimatem cyberpunka z dynamicznie zmieniającymi się neonowymi kolorami pod obrazkiem */
.container {
  position: relative;
  min-height: 100vh;
  padding: 20px;
  font-family: 'Orbitron', sans-serif;
  color: var(--main-color);
  text-shadow: 0 0 5px #000;
  overflow: hidden;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #ff00ff, #00ffff, #00ff00, #ffcc00);
  background-size: 400% 400%;
  animation: neonCycle 15s ease infinite;
  z-index: -2;
}

.container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Upewnij się, że ścieżka do obrazu jest poprawna – obraz powinien być w folderze public lub src */
  background: url('../../assets/cyberpunk-bg.webp') no-repeat center center;
  background-size: cover;
  opacity: 0.8;
  z-index: -1;
}

@keyframes neonCycle {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Pasek statusu */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Możesz dostosować style statusu, jeżeli są potrzebne */
.status p {
  margin: 0 10px;
  font-size: 1.2rem;
}

.score {
  font-weight: bold;
}

.scoreAnimation {
  animation: scorePop 0.5s ease-out;
}

@keyframes scorePop {
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

.startButton {
  background-color: #1a1a1a;
  border: 2px solid var(--main-color);
  padding: 10px 20px;
  color: var(--main-color);
  cursor: pointer;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.startButton:hover {
  transform: scale(1.1);
}

/* Sekcja duelowa - górny pasek z oknami użytkownika i przeciwnika */
.duelContainerTop {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px; 
  margin-bottom: 2px;
}

/* Styl dla okien (UserWindow i EnemyWindow) – używane wewnątrz duelContainerTop */
.duelSide {
  flex: 1;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid var(--main-color);
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  position: relative;
}

/* Animacje wjeżdżania */
@keyframes slideInLeft {
  0% { transform: translateX(-100%); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
}

@keyframes slideInRight {
  0% { transform: translateX(100%); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
}

.userWindowAnimation {
  animation: slideInLeft 0.8s ease-out;
}

.enemyWindowAnimation {
  animation: slideInRight 0.8s ease-out;
}

/* Styl dla napisu VS w środku - okrągła ramka z neonowym glow */
.vs {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* półprzezroczyste czarne tło */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-family: 'Bebas Neue', sans-serif; /* przykładowa czcionka z gry */
  font-weight: bold; /* pogrubiona czcionka */
  color: var(--main-color);
  z-index: 1;
  box-shadow: 0 0 20px var(--main-color), 0 0 30px var(--main-color), 0 0 40px var(--main-color); /* bardziej intensywny neon */
  animation: glowNeon 1s infinite alternate;
}



@keyframes glowNeon {
  0% {
    filter: brightness(1);
    box-shadow: 0 0 10px var(--main-color), 0 0 20px var(--main-color), 0 0 30px var(--main-color);
  }
  100% {
    filter: brightness(1.5);
    box-shadow: 0 0 20px var(--main-color), 0 0 30px var(--main-color), 0 0 40px var(--main-color);
  }
}

/* Sekcja pytania */
.questionSection {
  margin: 0px auto;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  border: 2px solid var(--main-color);
  border-radius: 10px;
  transition: opacity 0.5s, transform 0.5s;
}

/* TimerBar umieszczony w sekcji pytania nad numerem wyzwania */
.timerBarContainer {
  width: 100%;
  height: 6px;
  background-color: #222;
  border: 1px solid var(--main-color);
  border-radius: 3px;
  margin-bottom: 10px;
  overflow: hidden;
}

.timerBarFill {
  height: 100%;
  background-color: var(--main-color);
  transition: width 0.5s ease;
}

/* Lista odpowiedzi */
.answers {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.answerButton {
  background-color: #0d0d0d;
  border: 2px solid var(--main-color);
  color: var(--main-color);
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 1rem;
}

.answerButton:hover {
  background-color: #1a1a1a;
}

.selected {
  border-color: #ff00c8;
  transform: scale(1.05);
}

.correct {
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: #000;
  animation: positiveGlow 1s ease;
}

.incorrect {
  background-color: #ff073a;
  border-color: #ff073a;
  animation: shake 0.5s ease;
}

@keyframes positiveGlow {
  0% { transform: scale(1); box-shadow: 0 0 0px var(--main-color); }
  50% { transform: scale(1.05); box-shadow: 0 0 20px var(--main-color); }
  100% { transform: scale(1); box-shadow: 0 0 0px var(--main-color); }
}

@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-10px); }
  40% { transform: translateX(10px); }
  60% { transform: translateX(-10px); }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

/* Przycisk "Wykonaj Atak" (mrugający na czerwono) */
.checkButton {
  display: block;
  margin: 20px auto;
  background-color: #1a1a1a;
  border: 2px solid var(--main-color);
  padding: 10px 20px;
  color: var(--main-color);
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;
  animation: blinkRed 3s infinite;
}

@keyframes blinkRed {
  0%, 100% { background-color: #1a1a1a; }
  50% { background-color: #ff0000; }
}

.checkButton:hover {
  transform: scale(1.1);
  background-color: #333;
}

/* Dolny pasek – BottomBar */
.bottomBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  margin-top: 20px;
}

/* Przyciski w dolnym pasku */
.navButton,
.explanationButton,
.attackButton {
  padding: 10px 15px;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  background-color: #1a1a1a;
  color: var(--main-color);
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.3s, background-color 0.3s;
}

.navButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.navButton:hover:not(:disabled),
.explanationButton:hover:not(:disabled) {
  transform: scale(1.05);
  background-color: #333;
}

.attackButton {
  margin: 0 auto;
  display: block;
  animation: blinkRed 3s infinite;
}

.explanationButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.arrow {
  font-size: 1rem;
}

/* Informacje o użytkowniku wyświetlane w UserWindow */
.userHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.userInfo {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.pointsButton,
.rankingButton,
.levelButton {
  background-color: #1a1a1a;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--main-color);
  cursor: pointer;
  font-size: 0.9rem;
  transition: transform 0.3s, background-color 0.3s;
}

.pointsButton:hover,
.rankingButton:hover,
.levelButton:hover {
  transform: scale(1.05);
  background-color: #333;
}

.name {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  color: var(--main-color);
}

/* Animacje przejścia między pytaniami */
.collapse {
  animation: collapseAnimation 0.5s forwards;
  transform-origin: center;
}

@keyframes collapseAnimation {
  0% { transform: scaleX(1); opacity: 1; }
  100% { transform: scaleX(0); opacity: 0; }
}

.expand {
  animation: expandAnimation 0.5s forwards;
  transform-origin: center;
}

@keyframes expandAnimation {
  0% { transform: scaleX(0); opacity: 0; filter: drop-shadow(0 0 10px var(--main-color)); }
  100% { transform: scaleX(1); opacity: 1; filter: drop-shadow(0 0 20px var(--main-color)); }
}

/* Style dla sklepu */
.shopButton {
  background-color: #1a1a1a;
  border: 2px solid var(--main-color);
  padding: 5px 10px;
  color: var(--main-color);
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.3s;
  text-decoration: none;
}

.shopButton:hover {
  transform: scale(1.09);
}

.shopOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopContainer {
  background-color: #000;
  border: 2px solid var(--main-color);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  color: var(--main-color);
  text-align: center;
}

.shopContainer h2 {
  margin-top: 0;
}

.shopContainer ul {
  list-style: none;
  padding: 0;
}

.shopContainer li {
  margin-bottom: 20px;
}

.shopContainer button {
  background-color: #1a1a1a;
  border: 2px solid var(--main-color);
  color: var(--main-color);
  padding: 5px 10px;
  cursor: pointer;
  transition: transform 0.3s;
}

.shopContainer button:hover {
  transform: scale(1.05);
}

/* Style dla wyjaśnienia */
.explanationContainer {
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border: 2px solid var(--main-color);
  border-radius: 10px;
  color: var(--main-color);
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.legalBasis {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #ff00c8;
}

/* Styl dla przycisku rozwijania/zwijania wyjaśnienia */
.explanationToggle {
  margin-top: 20px;
  text-align: center;
}

.explanationButton {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  background-color: var(--background-color);
  color: var(--main-color);
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.explanationButton:hover {
  background-color: #111;
  transform: scale(1.05);
}

.arrow {
  margin-left: 8px;
  font-size: 1rem;
}


@keyframes neonGlow {
  0% {
    box-shadow: 
      0 0 15px var(--main-color), 
      0 0 30px var(--main-color), 
      0 0 40px var(--main-color), 
      inset 0 0 10px var(--main-color),         /* wewnętrzny glow */
      inset 0 10px 10px -10px var(--main-color),  /* efekt wewnętrzny na dole */
      inset 0 -10px 10px -10px var(--main-color); /* efekt wewnętrzny na górze */
  }
  50% {
    box-shadow: 
      0 0 25px var(--main-color), 
      0 0 40px var(--main-color), 
      0 0 50px var(--main-color), 
      inset 0 0 15px var(--main-color), 
      inset 0 10px 15px -10px var(--main-color), 
      inset 0 -10px 15px -10px var(--main-color);
  }
  100% {
    box-shadow: 
      0 0 15px var(--main-color), 
      0 0 30px var(--main-color), 
      0 0 40px var(--main-color), 
      inset 0 0 10px var(--main-color), 
      inset 0 10px 10px -10px var(--main-color), 
      inset 0 -10px 10px -10px var(--main-color);
  }
}

.media {
  display: block;
  max-width: 400px;
  width: 100%;
  height: auto;
  border: 2px solid var(--main-color); /* Ramka 1px */
  box-shadow: 
    0 0 15px var(--main-color), 
    0 0 30px var(--main-color), 
    0 0 40px var(--main-color), 
    inset 0 0 10px var(--main-color),         /* wewnętrzny glow */
    inset 0 10px 10px -10px var(--main-color),  /* efekt wewnętrzny na dole */
    inset 0 -10px 10px -10px var(--main-color); /* efekt wewnętrzny na górze */
  animation: neonGlow 1.5s infinite alternate;
}


.media {
  display: block;
  max-width: 400px;
  height: auto;
  margin: 0 auto;
  width: 100%;
  height: auto;
  border: 1px solid var(--main-color); /* Ramka 1px */
  box-shadow: 
    0 0 15px var(--main-color), 
    0 0 30px var(--main-color), 
    0 0 40px var(--main-color), 
    inset 0 0 10px var(--main-color); /* Poświata wewnętrzna */
  animation: neonGlow 1.5s infinite alternate; /* Animacja pulsowania */
}

/* Dostosowanie dla małych ekranów */
@media (max-width: 600px) {
  .media {
    max-width: 100%;
    padding: 0 10px; /* opcjonalnie marginesy wewnętrzne */
  }
}

/* start page */
.categoriesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 16px; /* odstęp między przyciskami */
  margin: 20px 0;
}

.categoryButton {
  aspect-ratio: 1 / 1; /* zapewnia kwadratowy kształt */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.categoryButton:hover {
  background-color: #e0e0e0;
}

/* Animacja rozwijania mediów od lewej do prawej */
/* @keyframes expandMedia {
  0% {
    transform: scaleX(0);
    opacity: 0;
  }
  50% {
    transform: scaleX(1.05);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
} */

/* Neonowa linia prowadząca */
/* @keyframes neonLine {
  0% {
    left: -100%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 100%;
  }
} */

/* Efekt szumu (krótka animacja zakłócająca) */
@keyframes staticNoise {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Kontener opakowujący medię z animacjami – wyśrodkowany poziomo */
/* Dodano transform-origin, aby animacja rozwijania odbywała się od lewej */
.mediaWrapper {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0 auto; /* Wyśrodkowanie poziome */
  transform-origin: left center; /* Kluczowe ustawienie, aby efekt był rozwijaniem od lewej */
  animation: expandMedia 1s ease-out forwards;
}

/* Klasa dla samego elementu mediów (wideo/obraz) */
.media {
  display: block;
  max-width: 400px;
  width: 100%;
  height: auto;
}

/* Neonowa linia – pseudoelement */
.mediaWrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 10px;
  height: 100%;
  background: #CB00B7;
  filter: blur(2px);
  animation: neonLine 0.8s ease-out forwards;
}

/* Efekt szumu – pseudoelement (użyj obrazka z szumem lub zastąp gradientem) */
.mediaWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('/public/path_to_noise_image.png'); /* Upewnij się, że ścieżka jest poprawna względem folderu public */
  opacity: 1;
  animation: staticNoise 0.5s ease-out forwards;
  pointer-events: none;
}
