/* Globalny reset box-sizing */
* {
  box-sizing: border-box;
}

/* Kontener obejmujący cały widok */
.container {
  margin: 0;
  padding: 0; /* Usuwamy padding, aby film w tle zajmował całą przestrzeń */
  color: var(--main-color);;
  text-align: center;
  min-height: 100vh;
  background-color: transparent;
  position: relative;
}

/* Wideo jako tło */
.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

/* Nakładka przyciemniająca wideo */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dostosuj wartość alfa, jeśli potrzebujesz więcej lub mniej przyciemnienia */
  z-index: -1;
}

/* Wewnętrzny kontener dla zawartości – tutaj dodajemy padding */
.content {
  position: relative;
  padding: 10px; /* Padding przeniesiony do zawartości */
  z-index: 1;
}

/* Styl opisu */
.description {
  color: var(--accent-color);
  margin-bottom: 5px;
  font-size: 1.5rem;
}

/* Siatka kategorii */
.categoriesGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  max-width: 600px;
  justify-content: center;
  margin: 10px auto;
}

/* Przycisk kategorii */
.categoryButton {
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--accent-color);
  font-weight: bold;
  border: 2px solid #10141A;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.categoryButton:hover {
  background-color: #222;
  border-color: var(--accent-color);
}

/* Przycisk startowy */
.startButton {
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background-color: var(--accent-color);
  color: #000;
  font-weight: bold;
  transition: background-color 0.3s;
}

.startButton:hover {
  background-color: #e600d0;
}

/* Wybrana kategoria */
.selectedCategory {
  background-color: var(--accent-color);
  color: #000;
  border-color: #10141A;
}

@media (max-width: 480px) {
  .categoryButton {
    width: 60px;
    height: 60px;
    font-size: 1.2rem;
  }
}

.errorMessage {
  color: #ff0000;
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-align: center;
}
