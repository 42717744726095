.logoContainer {
  position: relative;
  margin: 5px 15px 10px;
  width: 144px; 
  height: 50px; 
  cursor: pointer;
  top:0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
