/* Login.module.css */

.form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;  
  }
  
  .input-field {
    border: 1px solid var(--main-color);
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    color: var(--text-color);
  }
  
  .login-button {
    background-color: var(--button-color);
    color: var(--text-color);
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    width: auto; /* Guzik nie zajmuje całej szerokości */
  }
  
  .login-button:hover {
    background-color: var(--button-hover-color);
    color: var(--second-color);
  }