.hamburgerMenu {
    background: var(--background-color);
    padding: 15px;
  }
  
  .menuIcon {
    background: var(--background-color);
    border: none;
    color: var(--text-color);
    cursor: pointer; /* zmiana kursora przy najechaniu */
  }
  
  .menuContent {
    background: var(--background-color);
    padding: 10px;
    display: flex;
    flex-direction: column; /* ułożenie elementów w kolumnie */
    gap: 15px; /* odstęp między pozycjami */
    font-size: 1.4em;
  }
  
  .iconLink {
    background: var(--background-color);
    border: none;
    color: var(--main-color);
    cursor: pointer;
  }
  
  .menuText {
    color: var(--text-color);
  }
  
  .button {
    background: var(--background-color);
    border: none;
    cursor: pointer;
    color: var(--text-color);
  }
  