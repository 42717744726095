.cartIcon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;
  }
  
  .cartIcon img {
    width: 100%;
    height: auto;
  }
  
  /* Opcjonalnie – jeżeli chcesz wyświetlać licznik produktów w koszyku */
  .cartIconBadge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  