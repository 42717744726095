.energyBarContainer {
    width: 100%;
    margin: 5px 0;
  }
  
  .label {
    display: block;
    text-align: center;
    font-size: 0.9rem;
    margin-bottom: 5px;
    color: var(--main-color);
  }
  
  .barBackground {
    width: 100%;
    height: 6px;
    background-color: #333;
    border: 1px solid var(--main-color);
    border-radius: 5px;
  }
  
  .barFill {
    height: 100%;
    border-radius: 5px;
    transition: width 0.5s ease, background-color 0.5s ease;
  }

 