.window {
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid var(--main-color);
  border-radius: 10px;
  padding: 5px;
  width: 100%;
  min-width: 150px;
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  /* border-radius: 50%; */
  /* border: 2px solid var(--main-color); */
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  flex: 1;
}

.energyWrapper {
  /* Możesz ustawić margines, by oddzielić EnergyBar od nazwy, np.: */
  margin-top: 2px;
}

@media (max-width: 450px) {
  .header {
    gap: 5px;
  }

  .window {
    padding: 1px;
  }
  
  .image {
    width: 50px;
    height: 50px;
  }
}