.defeatContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  color: #fff;
}

.userImage {
  width: 350px; /* Ustawiamy szerokość zdjęcia na 350px */
  filter: sepia(100%) hue-rotate(-50deg) saturate(500%) brightness(90%);
  margin-bottom: 20px;
  border: 3px solid var(--main-color);
}

.congratsMessage {
  font-size: 2rem;
  margin-bottom: 10px;
  text-shadow: 0 0 10px #ff4500, 0 0 20px #ff4500;
  animation: burningText 1.5s infinite alternate;
}

.defeatSubMessage {
  font-size: 1.25rem;
  margin-bottom: 20px;
  text-align: center;
}

.scoreMessage {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Styl dla komunikatu zachęcającego do logowania */
.loginPrompt {
  font-size: 1.25rem;
  margin-bottom: 20px;
  text-align: center;
  color: var(--main-color);
}

.continueButton {
  background-color: rgba(26, 26, 26, 0.5);
  border: 2px solid var(--main-color);
  padding: 10px 20px;
  color: var(--main-color);
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.3s, background-color 0.3s;
  text-decoration: none;
}

/* Nowy kontener, który ustawia przyciski obok siebie */
.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

/* Definicja animacji burningText */
@keyframes burningText {
  from {
    text-shadow: 0 0 10px #ff4500, 0 0 20px #ff4500;
  }
  to {
    text-shadow: 0 0 20px #ff4500, 0 0 30px #ff4500;
  }
}

/* Styl dla małych ekranów */
@media (max-width: 768px) {
  .congratsMessage {
    font-size: 1.5rem;
  }
  
  .defeatSubMessage {
    font-size: 1rem;
  }
  
  .scoreMessage {
    font-size: 1.25rem;
  }
  
  /* Zmniejszamy obraz oraz przestrzeń nad i pod obrazem */
  .userImage {
    width: 250px;
    margin: 5px auto;
  }
  
  /* Przesuwamy guziki wyżej przez zmniejszenie marginesu górnego */
  .buttonContainer {
    margin-top: 10px;
  }
  
  .loginPrompt {
    font-size: 1rem;
    margin-bottom: 15px;
  }
}
