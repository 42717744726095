/* BottomBar.module.css */
.bottomBar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    /* border-top: 2px solid var(--main-color); */
    margin-top: 20px;
  }
  
  .attackButton {
    padding: 10px 15px;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    background-color: var(--background-color);;
    color: var(--main-color);
    cursor: pointer;
    font-size: 1rem;
    transition: transform 0.3s, background-color 0.3s;
    margin: 0 auto; /* Wyśrodkowanie */
    display: block;
    animation: blinkRed 3s infinite;
  }
  
  @keyframes blinkRed {
    0%, 100% { background-color: #1a1a1a; }
    50% { background-color: #9e004a; }
  }
  
  .navButton,
  .explanationButton {
    padding: 10px 15px;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    background-color: #1a1a1a;
    color: var(--main-color);
    cursor: pointer;
    font-size: 1rem;
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .navButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .navButton:hover:not(:disabled),
  .explanationButton:hover:not(:disabled) {
    transform: scale(1.05);
    background-color: #333;
  }
  
  .explanationButton {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .arrow {
    font-size: 1rem;
  }
  