.answers {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .answerButton {
    background-color: var(--background-color);
    border: 2px solid var(--main-color); 
    color: var(--main-color);
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 1rem;
  }
  
  .answerButton:hover {
    background-color: var(--background-color);
  }
  
  .selected {
    border-color: var(--accent-color);
    transform: scale(1.05);
  }
  
  .correct {
    background-color: var(--main-color);
    border-color: var(--main-color);
    color: #000;
    animation: positiveGlow 1s ease;
  }
  
  .incorrect {
    background-color: #ff073a;
    border-color: #ff073a;
    animation: shake 0.5s ease;
  }
  
  @keyframes positiveGlow {
    0% { transform: scale(1); box-shadow: 0 0 0px var(--main-color); }
    50% { transform: scale(1.05); box-shadow: 0 0 20px var(--main-color); }
    100% { transform: scale(1); box-shadow: 0 0 0px var(--main-color); }
  }
  
  @keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }
  