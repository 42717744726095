/* Ustawienie globalne, aby padding i border były wliczane do szerokości elementu */
* {
  box-sizing: border-box;
}

.heading {
  margin: 0;
  margin-bottom: 5px;
  font-size: 1.1em;
}

/* Kontener rejestracji z mniejszą szerokością 
   UWAGA: Usuwamy min-height: 100vh, aby nie wymuszać pełnego ekranu. */
.regContainer {
  margin: 0 auto;
  max-width: 650px;
  width: 100%;
  color: var(--text-color);
  text-align: center;
  background-color: transparent;
  position: relative;
}

/* Wewnętrzny kontener zawartości */
.regContent {
  position: relative;
  z-index: 1;
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
}

/* Formularz rejestracji */
.regContent form {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Odstęp między polami */
}

/* Inputy oraz select */
.regContent input,
.regContent select {
  width: 100%;
  padding: 8px; /* Mniejsze paddingi */
  border: 1px solid var(--main-color);
  border-radius: 4px;
  font-size: 0.9rem; /* Mniejsza czcionka */
  background-color: rgba(255, 255, 255, 0.3);
  color: var(--text-color);;
}

/* Placeholder */
.regContent input::placeholder {
  color: var(--text-color);;
}

/* Grupa checkboxów */
.regCheckboxGroup {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Mniejsze odstępy między checkboxami */
  text-align: left;
  margin-top: 8px;
}

/* Pojedynczy wiersz checkboxa */
.regCheckboxItem {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.75rem; /* Mniejsza czcionka */
}

/* Zapewnienie łamania długich tekstów w etykietach checkboxów */
.regCheckboxItem label {
  word-break: break-word;
}

/* Klasa dla wiersza, który ma zajmować całą szerokość */
.regCheckboxFullRow {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 0.75rem;
  margin-top: 5px;
}

/* Styl checkboxa */
.regCheckboxItem input[type='checkbox'] {
  width: 12px;
  height: 12px;
  margin: 0;
}

/* Przycisk rejestracji */
.regButton {
  padding: 10px 20px; /* Mniejsze wymiary */
  font-size: 0.9rem;
  cursor: pointer;
  background-color: var(--button-color);
  color: var(--text-color);
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.regButton:hover {
  background-color: var(--button-hover-color);
}

/* Komunikat błędu */
.regErrorMessage {
  color: #ff0000;
  margin-bottom: 10px;
  font-size: 1rem;
  text-align: center;
}

/* Dodatkowe ustawienia dla małych ekranów */
@media (max-width: 480px) {
  .regContent input,
  .regContent select {
    font-size: 1.1rem;
    padding: 8px;
  }

  .regButton {
    padding: 5px 16px;
    font-size: 0.85rem;
  }

  .heading{
    font-size: 1.0em;
  }
}
