/* ExamQuestions.module.css */

/* Reset */
* {
  box-sizing: border-box;
}

/* Główny kontener strony */
.contactContainer {
  padding: 0;
  margin: 0 auto;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Wewnętrzna zawartość */
.content {
  padding: 20px;
  max-width: 900px;
  width: 100%;
  z-index: 1;
  /* text-align: center; */
  margin: 30px;
}

/* Nagłówek */
.mainHeader {
  margin: 0;
  font-size: 2rem;
  color: var(--main-color);
}

/* Intro tekst */
.intro {
  color: var(--accent-color);
  margin-bottom: 20px;
  font-size: 1.5rem;
}

/* Sekcja z pytaniami */
.reasons {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
}

/* Lista odpowiedzi */
.reasonList {
  list-style-type: none;
  padding: 0;
  color: #ffffff;
}

.reasonList li {
  margin: 10px 0;
  font-size: 1.2rem;
  text-align: left;
}

.reasonList p {
  margin: 5px 0 0 20px;
  font-size: 1.1rem;
  color: var(--text-muted);
}

.reasonList ul {
  margin-top: 5px;
  margin-left: 20px;
  list-style-type: disc;
  color: var(--text-muted);
}

/* Kontener przycisków */
.buttonContainer {
  margin-top: 30px;
  display: flex;
  gap: 15px;
  justify-content: center;
}

/* Styl przycisków */
.primaryButton,
.secondaryButton {
  background: transparent;
  border: 2px solid var(--main-color);
  color: var(--main-color);
  padding: 12px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s, color 0.3s;
  text-decoration: none;
  border-radius: 6px;
}

.primaryButton {
  animation: pulsate 2s infinite;
}

.primaryButton:hover,
.secondaryButton:hover {
  background-color: var(--accent-color);
  color: #000;
  transform: scale(1.05);
}

.customAnswer p {
  color: #95cfff; /* niebieski odcień */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.05rem;
  font-weight: 500;
  margin-top: 0.4em;
}


/* Efekt pulsowania */
@keyframes pulsate {
  0% {
    background-color: transparent;
    box-shadow: 0 0 5px var(--accent-color);
  }
  50% {
    background-color: var(--accent-color);
    box-shadow: 0 0 20px #ff0b5c;
  }
  100% {
    background-color: transparent;
    box-shadow: 0 0 5px #f801a6;
  }
}

/* Responsywność */
@media (max-width: 768px) {
  .reasonList li {
    font-size: 1.05rem;
  }
}

@media (max-width: 480px) {
  .reasonList li {
    font-size: 1rem;
  }

  .intro {
    font-size: 1.2rem;
  }

  .content {
  padding: 3px;
  width: 100%;
  z-index: 1;
  /* text-align: center; */
  margin: 3px;
}
}
