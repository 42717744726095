.cartModal {
    padding: 20px;
    background: var(--background-color);
    border-radius: 8px;
    max-width: 500px;
    margin: 0 auto;
    color: var(--text-color);; 
  }
  
  .cartList {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .cartItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
  }
  
  .itemInfo {
    flex: 1;
    margin-right: 10px;
  }
  
  .removeButton {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Styl przycisku "Zapłać" */
  .payButton {
    background-color: var(--button-color);
    color: var(--text-color);
    border: none;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }
  
  
  .payButton:hover {
    background-color: var(--main-color);
  }
  