.footer {
  background-color: #000;
  padding: 10px;
  color: #fff;
  margin: 0; /* usuwa margines, który może powodować biały pasek */
}
  
.footerLinks {
  margin: 10px 0;
}
  
.footerLinks a {
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
}
  
.footerLinks a:hover {
  text-decoration: underline;
}

@media (max-width: 450px) {
  .footer {
    background-color: #000;
    padding: 3px;
    color: #fff;
    margin: 0; /* usuwa margines, który może powodować biały pasek */
    font-size: smaller;
  }
}