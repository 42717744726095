/* Ustawienia ogólne dla kontenera (kolory, czcionka, padding) */
.cyberpunkContainer {
    color: var(--second-color);
    font-family: 'Orbitron', sans-serif;
    padding: 40px;
    text-align: center;
    min-height: 100vh;
    background-color: rgb(207, 207, 207);
}

/* Kontener walki – rozmieszcza boksy z większymi odstępami */
.fightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-bottom: 40px;
}

/* Boksy przeciwnika i gracza z cieniowaniem i animacją przy hoverze */
.enemyBox,
.userBox {
    background: var(--background-color);
    border: 2px solid var(--main-color);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 15px var(--main-color);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
}

.enemyBox:hover,
.userBox:hover {
    transform: scale(1.05);
    box-shadow: 0 0 25px var(--main-color);
}

/* Zdjęcia jako kwadraty 250x250, z efektem obcięcia (object-fit: cover) */
.cyberImage {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border: 2px solid var(--main-color);
    margin: 10px auto;
}

/* Sekcja statystyk */
.stats {
    margin-top: 15px;
    text-align: left;
    font-size: 1rem;
    line-height: 1.5;
}

/* Wiersz statystyki – etykieta i wartość wyświetlane obok siebie */
.statRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}

.statLabel {
    flex: 1;
    text-align: left;
}

.statValue {
    flex: 1;
    text-align: right;
}

/* Kontener separatora VS */
.vsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Styl napisu VS */
.vsText {
    font-size: 4rem;
    font-weight: bold;
    color: var(--accent-color);
    text-shadow: 0 0 10px var(--accent-color);
}

/* Kontener dla przycisku */
.buttonContainer {
    margin-top: 20px;
}

.startBattleButton {
    background: transparent;
    border: 2px solid var(--main-color);
    color: var(--main-color);
    padding: 12px 30px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: transform 0.3s;
    animation: pulsate 2s infinite;
}

@keyframes pulsate {
    0% {
        background-color: transparent;
        box-shadow: 0 0 5px var(--accent-color);
    }
    50% {
        background-color: var(--accent-color);
        box-shadow: 0 0 20px var(--accent-color);
    }
    100% {
        background-color: transparent;
        box-shadow: 0 0 5px var(--accent-color);
    }
}

.startBattleButton:hover {
    transform: scale(1.05);
}


/* Nowe style dla przycisku Sklep */

/* Kontener dla przycisku Sklep pod kartą użytkownika */
.shopButtonContainer {
    margin-top: 15px;
}

/* Styl przycisku Sklep */
.shopButton {
    background: transparent;
    border: 2px solid var(--main-color);
    color: var(--main-color);
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s;
}

.shopButton:hover {
    background: var(--main-color);
    color: #0d0d0d;
    transform: scale(1.05);
}

/* Sekcja artefaktów – małe zdjęcia */
.artefactsContainer {
    margin-top: 20px;
    padding: 10px;
    background: var(--background-color);
    border: 2px solid var(--main-color);
    border-radius: 8px;
    color: var(--main-color);
    text-align: center;
}

.artefactsBox {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.artifactSlot {
    width: 50px;
    height: 50px;
    border: 1px dashed var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--background-color);
}

.artifactImage {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

/* Media query dla mniejszych ekranów */
@media (max-width: 768px) {
    .cyberpunkContainer {
        padding: 20px;
    }
    
    .fightContainer {
        gap: 20px;
        flex-direction: column;
    }
    
    .vsText {
        font-size: 2.5rem;
        margin: 0.5rem;
        padding: 0.5rem;
    }
    
    .enemyBox,
    .userBox {
        width: 100%;
        max-width: 300px;
        padding: 2px;
    }
    
    .cyberImage {
        width: 280px;
        height: 280px;
    }
}

.emptyArtifact {
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
  }


  /* Domyślnie ukrywamy przycisk START w środku */
.vsStartButton {
  display: none;
  background: transparent;
  border: 2px solid var(--main-color);
  color: var(--main-color);
  padding: 10px 25px;
  font-size: 1.2rem;
  cursor: pointer;
  animation: pulsate 2s infinite;
  transition: transform 0.3s;
  margin-top: 15px;
}

.vsStartButton:hover {
  transform: scale(1.05);
}

/* Ukryj napis VS i pokaż START na małych ekranach */
@media (max-width: 768px) {
  .vsText {
    display: none;
  }

  .vsStartButton {
    display: inline-block;
  }

  /* Możesz też schować dolny przycisk START, jeśli chcesz uniknąć duplikacji */
  .buttonContainer {
    display: none;
  }
}
