.rating {
  /* Całe tło zajmuje 100% widoku */
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 20px;
}

.content {
  background-color: rgba(255, 255, 255, 0.4);
  border: 2px solid var(--main-color);
  border-radius: 10px;
  padding: 10px;
  max-width: 600px;
  margin: 80px auto 0; /* Dodany górny margines, aby przycisk nie nachodził na listę */
  box-sizing: border-box;
}

.header {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--main-color);
}

.subheader {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: var(--accent-color);
}

.rankingList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.rankingItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--main-color);
  padding: 8px 0;
  color: var(--main-color);
}

.rankingItem:last-child {
  border-bottom: none;
}

.rankPosition {
  font-weight: bold;
  width: 2rem;
  text-align: center;
}

.userName {
  flex: 1;
  margin: 0 10px;
  text-align: left;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userCorrect {
  color: var(--accent-color);
  text-align: center;
  padding-right: 10px;
  flex-shrink: 0;
}

.backButton {
  position: absolute;
  top: 20px;
  left: 20px;
  background: rgba(255, 255, 255, 0.4);
  border: 2px solid var(--main-color);
  color: var(--main-color);
  padding: 12px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  z-index: 1000;
}

/* Stylizacja dla małych ekranów */
/* Stylizacja dla małych ekranów */
@media (max-width: 480px) {
  .rankingItem {
    flex-direction: row; /* Utrzymujemy elementy w jednym wierszu */
    align-items: center;
    justify-content: space-between;
  }
  .userCorrect {
    margin-top: 0; /* Usuwamy margines górny */
    align-self: center;
  }
}

