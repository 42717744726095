.window {
  background-color: rgba(0, 0, 0, 0.3);
  border: 2px solid var(--main-color);
  border-radius: 10px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.counter {
  flex: 0 0 auto;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 3px;
  flex: 1;
  margin: 0 10px;
}

/* Kontener dla nazwy oraz badge */
.nameContainer {
  display: inline-flex;
  align-items: center;
}

/* Styl dla nazwy użytkownika */
.name {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  color: var(--main-color);
}

/* Badge z poziomem – prostokąt na tej samej wysokości co nazwa */
.levelBadge {
  background-color: #E600CF;
  color: #fff;
  padding: 1px 4px;
  font-size: 0.7rem;
  font-weight: bold;
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 1px; /* Prostokąt, bez zaokrągleń */
}

/* Obrazek użytkownika */
.imageContainer {
  flex: 0 0 auto;
}

.image {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.energySection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.counterContainer {
  flex-shrink: 0;
}

.artifactsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 2px; /* 2px odstęp między artefaktami */
}

.artifactSlot {
  /* Upewnij się, że sloty mają określony rozmiar – np. 40x40px, można dostosować */
  width: 40px;
  height: 40px;
}

.artifactImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px dashed var(--main-color); /* Przerywana ramka */
}

/* Styl dla pustego slotu (gdy nie ma artefaktu) */
.emptyArtifact {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  border: 1px dashed var(--main-color);
}

@media (max-width: 450px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .counterContainer {
    margin-top: 5px;
  }

  .artifactsGrid {
    gap: 2px;
  }

  .window {
    padding: 1px;
  }

  .image {
    width: 50px;
    height: 50px;
  }

  .name {
    font-size: 0.8rem;
    font-weight: normal;
    margin: 0;
    color: var(--main-color);
  }
}
