/* Globalny reset box-sizing */
* {
    box-sizing: border-box;
  }
  
  /* Kontener strony ThankYou */
  .thankYouContainer {
    margin: 0 auto;
    padding: 20px;
    color: var(--main-color);;
    text-align: center;
    min-height: 100vh;
    background-color: transparent;
    position: relative;
  }
  
  /* Główny kontener zawartości (wiadomości) */
  .thankYouContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background: rgba(0, 0, 0, 0.6);
    border: 2px solid #10141A;
    padding: 20px;
    border-radius: 0px;
    max-width: 450px;
    margin: 20px auto;
  }
  
  /* Tytuł podziękowania */
  .thankYouTitle {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .loginReminder {
    font-size: 1.2rem;
    color: var(--accent-color);
  }

  /* Tekst wiadomości podziękowania */
  .thankYouText {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  /* Przycisk (np. powrót do strony głównej lub dalsze akcje) */
  .thankYouButton {
    padding: 12px 24px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    background-color: var(--accent-color);
    color: #000;
    font-weight: bold;
    transition: background-color 0.3s, border-color 0.3s;
    border-radius: 0px;
  }
  
  .thankYouButton:hover {
    background-color: #e600d0;
  }
  
  /* Responsywność */
  @media (max-width: 480px) {
    .thankYouContent {
      max-width: 90%;
    }
  }
  