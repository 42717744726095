.typewriter-container {
    font-family: monospace;
    background-color: #000;
    color: #0f0;
    padding: 20px;
    font-size: 1.5em;
    white-space: pre-wrap;
  }
  
  .blinking-cursor {
    animation: blink 1s step-end infinite;
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  