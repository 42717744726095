/* Globalny reset box-sizing */
* {
  box-sizing: border-box;
}

/* Kontener obejmujący cały widok */
.container {
  margin: 0;
  padding: 0;
  color: var(--main-color);;
  text-align: center;
  min-height: 100vh;
  background-color: transparent;
  position: relative;
}

/* Wideo jako tło */
.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
  background-color: var(--background-color);
}

/* Nakładka przyciemniająca wideo */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: -1;
}

/* Wewnętrzny kontener dla zawartości */
.content {
  position: relative;
  padding: 20px;
  z-index: 1;
}

/* Styl opisu wstępnego */
.intro {
  color: var(--accent-color);
  margin-bottom: 20px;
  font-size: 1.5rem;
}

/* Grid z opcjami cenowymi */
.pricingGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  gap: 5px; /* Zmniejszony odstęp między kartami */
  justify-content: center;
}

/* Karta oferty cenowej */
.pricingCard {
  background-color: rgba(204, 204, 204, 0.5);
  padding: 20px;
  border-radius: 0px;
  width: 90%;
  max-width: 300px;
  position: relative; /* Dodane, aby umieścić badge */
  font-size: 1.3rem;
}

/* Badge dla BEST SELLER */
.badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgb(255, 217, 0);
  color: black;
  padding: 1px 4px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 0.8rem;
}

/* Cena właściwa */
.price {
  font-size: 1.6rem;
  margin: 10px 0;
  color: var(--second-color);
}

/* Przekreślona cena promocyjna */
.discountPrice {
  font-size: 1.2rem;
  text-decoration: line-through;
  color: #eb7272;
  margin-bottom: 5px;
}

/* Opis oferty */
.description {
  font-size: 1.2rem;
  color: var(--text-color);
  margin-bottom: 15px;
}

/* Guzik "Kup dostęp" */
.buyButton {
  background: transparent;
  border: 2px solid var(--main-color);
  color: var(--text-color);
  padding: 12px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.3s;
  animation: pulsate 2s infinite;
}

@keyframes pulsate {
  0% {
    background-color: transparent;
    box-shadow: 0 0 5px var(--main-color);
  }
  50% {
    background-color: var(--main-color);
    box-shadow: 0 0 20px var(--main-color);
  }
  100% {
    background-color: transparent;
    box-shadow: 0 0 5px var(--main-color);
  }
}

.buyButton:hover {
  background-color: var(--hover-bacground-color);
  transform: scale(1.05);
}

/* Responsywność */
@media (max-width: 480px) {
  .pricingCard {
    padding: 15px;
  }
  .intro {
    font-size: 1.2rem;
  }
}
