/* Globalny reset box-sizing */
* {
    box-sizing: border-box;
  }
  
  /* Kontener obejmujący cały widok */
  .container {
    margin: 0;
    padding: 0; /* Usuwamy padding, aby film w tle zajmował całą przestrzeń */
    color: var(--second-color);
    text-align: center;
    min-height: 100vh;
    background-color: transparent;
    position: relative;
  }
  
  /* Wideo jako tło */
  .backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }
  
  /* Nakładka przyciemniająca wideo */
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: -1;
  }
  
  /* Wewnętrzny kontener dla zawartości */
  .content {
    position: relative;
    padding: 20px;
    z-index: 1;
    max-width: 400px;
    margin: 0 auto;
  }
  
  /* Formularz rejestracji */
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Styl inputów oraz selecta */
  input,
  select {
    width: 100%;
    padding: 10px;
    border: 2px solid #10141A;
    border-radius: 4px;
    font-size: 1rem;
    color: #02F7E2;
  }
  
  /* Placeholder */
  input::placeholder {
    color: var(--main-color);
  }
  
  /* Grupa checkboxów – umieszczona na samym dole */
  .checkboxGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    margin-top: 20px;
  }
  
  /* Pojedynczy wiersz checkboxa: dwie kolumny */
  .checkboxItem {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
  }
  
  /* Styl przycisku płatności */
  .payButton {
    padding: 12px 24px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    background-color: var(--accent-color);
    color: #000;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .payButton:hover {
    background-color: var(--accent-color);
  }
  
  /* Komunikat błędu */
  .errorMessage {
    color: #ff0000;
    margin-bottom: 10px;
    font-size: 1.2rem;
    text-align: center;
  }

  .discountButton {
    border: 1px solid #02F7E2;
    border-radius: 0;
    padding: 8px 12px;
    background: transparent;
    cursor: pointer;
    color: #02F7E2;
  }
  
  /* Responsywność */
  @media (max-width: 480px) {
    .content {
      padding: 10px;
    }
    
    input,
    select {
      font-size: 0.9rem;
      padding: 8px;
    }
    
    .payButton {
      padding: 10px 20px;
      font-size: 0.9rem;
    }

    .discountContainer {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      
      .discountInput {
        flex: 1;
      }
      
      .discountButton {
        border: 1px solid #02F7E2;
        border-radius: 0;
        padding: 8px 12px;
        background: transparent;
        cursor: pointer;
      }

      .discountContainer {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      
      .discountButton {
        border: 1px solid #02F7E2;
        padding: 6px 12px;
        background-color: transparent;
        cursor: pointer;
      }
      
      /* Styl dla przycisku gdy jest nieaktywny */
      .discountButton:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
      
  }
  