.congratsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Zamiast centrowania w pionie, zawartość zaczyna się od góry */
  justify-content: flex-start;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  /* Zwiększony padding u góry, aby dodać trochę przestrzeni od krawędzi */
  padding: 10px 20px 20px;
  text-align: center;
  color: var(--main-color);
  animation: fadeIn 1s ease-out;
  /* Opcjonalnie, jeżeli zawartość czasem przekracza wysokość ekranu */
  overflow-y: auto;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Opcjonalnie: Zmniejszenie maksymalnej wysokości obrazu, by upewnić się,
że cała zawartość mieści się na ekranie */
.enemyImage {
  width: 350px;
  max-height: 40vh; /* ograniczenie wysokości obrazu do 40% wysokości widoku */
  filter: sepia(100%) hue-rotate(-50deg) saturate(500%) brightness(90%);
  object-fit: cover;
  border: 3px solid var(--main-color);
  margin-bottom: 20px;
}

.congratsMessage {
  font-size: 2rem;
  margin-bottom: 10px;
  text-shadow: 0 0 10px #ff4500, 0 0 20px #ff4500;
  animation: burningText 1.5s infinite alternate;
}

@keyframes burningText {
  0% {
    filter: brightness(1);
    text-shadow: 0 0 10px #ff4500, 0 0 20px #ff4500;
  }
  100% {
    filter: brightness(1.5);
    text-shadow: 0 0 20px #ff4500, 0 0 30px #ff4500;
  }
}

.scoreMessage {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

/* Styl dla komunikatu zachęcającego do logowania */
.loginPrompt {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: var(--main-color);
}

.continueButton {
  background-color: #1a1a1a;
  border: 2px solid var(--main-color);
  padding: 10px 20px;
  color: var(--main-color);
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.3s, background-color 0.3s;
  text-decoration: none;
}

.continueButton:hover {
  transform: scale(1.1);
  background-color: #333;
}

/* Kontener dla przycisków */
.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
}
