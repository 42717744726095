.artefactContainer {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .artefactImage {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
  
  .artefactOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-align: center;
  }
  