/* Shop.module.css */

/* Ustawienia ogólne dla kontenera (kolory, czcionka, padding) */
.cyberpunkContainer {
  color: var(--main-color);
  font-family: 'Orbitron', sans-serif;
  padding: 40px;
  text-align: center;
  min-height: 100vh;
  position: relative;
}

/* Kontener nagłówka, aby przycisk "Wróć" był po lewej */
.header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

/* Tytuł sklepu */
.shopTitle {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 0 0 10px var(--main-color);
}

/* Informacja o posiadanych punktach */
.scoreInfo {
  font-size: 1.5rem;
  margin-bottom: 30px;
  text-shadow: 0 0 10px var(--main-color);
}

/* Kontener produktów */
.productsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

/* Karta produktu */
.productCard {
  background: rgba(0, 0, 0, 0.6);
  border: 2px solid var(--main-color);
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 0 15px var(--main-color);
  transition: transform 0.3s, box-shadow 0.3s;
}

.productCard:hover {
  transform: scale(1.05);
  box-shadow: 0 0 25px var(--main-color);
}

/* Obrazek produktu */
.productImage {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border: 2px solid var(--main-color);
  margin: 10px auto;
}

/* Nazwa produktu */
.productName {
  font-size: 1.5rem;
  margin: 10px 0;
  color: var(--main-color);
  text-shadow: 0 0 5px var(--main-color);
}

/* Opis produktu */
.productDescription {
  font-size: 1rem;
  margin: 10px 0;
}

/* Koszt produktu */
.productCost {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0;
}

/* Styl przycisku Kup / Zaloguj się */
.buyButton {
  background: transparent;
  border: 2px solid var(--main-color);
  color: var(--main-color);
  padding: 12px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  width: 100%;
}

.buyButton:hover {
  background: var(--main-color);
  color: #0d0d0d;
  transform: scale(1.05);
}

.buyButton:disabled {
  border-color: #555;
  color: #555;
  cursor: not-allowed;
}

/* Przycisk "Wróć" */
.backButton {
  background: rgba(0, 0, 0, 0.4);
  border: 2px solid var(--main-color);
  color: var(--main-color);
  padding: 12px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  z-index: 1000;
}

.backButton:hover {
  background: var(--main-color);
  color: #0d0d0d;
  transform: scale(1.05);
}

/* Kontener artefaktów */
.artefactsContainer {
  margin-top: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  color: var(--main-color);
}

/* Box na artefakty – 5 miejsc */
.artefactsBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

/* Slot pojedynczego artefaktu */
.artifactSlot {
  width: 18%;
  height: 100px;
  border: 2px dashed var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Obrazek artefaktu */
.artifactImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Media query dla mniejszych ekranów */
@media (max-width: 768px) {
  .cyberpunkContainer {
    padding: 20px;
  }
  
  .productsContainer {
    gap: 20px;
  }
  
  .productCard {
    width: 100%;
    max-width: 300px;
  }
  
  .productImage {
    width: 200px;
    height: 200px;
  }
}

.emptyArtifact {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
}

