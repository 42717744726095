.headerBackground {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background-color);
    padding: 0px 5px;
    color: var(--main-color);
    font-size: smaller;
  }
  
  .logoContainer {
    /* ewentualne dodatkowe style dla logo */
  }
  
  .rightContainer {
    display: flex;
    align-items: center;
  }
  
  .navItems {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 20px; /* odstęp między elementami a hamburger menu */
  }
  
  .hamburgerContainer {
    background: var(--background-color);
    padding: 5px;
  }
  
  /* Style dla guzików – tło var(--main-color), brak zaokrągleń */
  .logoutButton,
  .authButton {
    padding: 0.5rem 1rem;
    border: 2px solid var(--main-color);
    background-color: var(--background-color);
    color: var(--main-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .logoutButton:hover,
  .authButton:hover {
    background-color: var(--button-background-color);
    color: var(--second-color);
  }
  
  /* Style dla hamburger menu pasków */
  .hamburgerContainer span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: var(--main-color);
    margin: 4px 0;
  }
  
  /* Media query dla małych ekranów */
  @media screen and (max-width: 600px) {
    .navItems {
      display: none;
    }
  }
  