/* AnalogCounter.module.css */

.digitContainer {
    height: 25px; /* zmniejszona wysokość */
    width: 15px;  /* zmniejszona szerokość */
    overflow: hidden;
    display: inline-block;
    border: 1px solid #797979;
    margin: 0 1px; /* zmniejszony margines */
    border-radius: 4px;
    background: var(--text-color);
    color: var(--main-color);
  }
  
  .rollingContainer {
    transition: transform 1.0s ease-in-out;
  }
  
  .digit {
    height: 25px; /* zmniejszona wysokość */
    line-height: 25px; /* zmniejszony line-height */
    text-align: center;
    font-size: 0.75rem; /* zmniejszony rozmiar czcionki */
    font-family: monospace;
  }
  
  .counter {
    display: flex;
    align-items: center;
  }
  