/* src/css/modules/TimerBar.module.css */
.timerBarContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.timerBarBackground {
  flex-grow: 1;
  height: 6px;
  background-color: #4b4949;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 10px;
}

.timerBarFill {
  height: 100%;
  background-color: #ff5db9; /* Możesz zmienić kolor, np. w zależności od stanu */
  transition: width 1s linear;
}
