/* Tło modala – całe okno */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; /* wyśrodkowanie modala */
  z-index: 1001;
  overflow: hidden;     /* blokuje przewijanie tła strony */
}

/* Główny kontener modala */
.modalContent {
  background: var(--background-color);
  border: 1px solid var(--main-color);
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  /* Maksymalna wysokość – 90% ekranu */
  max-height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Ukrywamy overflow, scroll będzie w .scrollableContent */
  padding: 0 30px;
  box-sizing: border-box;
}

/* Przyciski zamykania – pozostają zawsze widoczne */
.closeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 1.2rem;
  color: var(--text-color);
  cursor: pointer;
  z-index: 1010;
}

/* Kontener przewijalnej treści – oddzielony od przycisku close */
.scrollableContent {
  /* rezerwujemy miejsce na przycisk close (40px) */
  margin-top: 40px;
  /* to jest kluczowe, aby można było scrollować długą treść */
  overflow-y: auto;
  /* flex: 1 sprawia, że ten kontener rośnie, by wypełnić wolne miejsce */
  flex: 1;
  padding: 0 15px; /* wewnętrzne marginesy dla czytelności */
  /* aby nie przekroczyć łącznej wysokości modala (90vh), odejmujemy górny margines (40px) */
  max-height: calc(90vh - 40px);
  box-sizing: border-box;
}

/* Przycisk rejestracji w modalu */
.registerButton {
  background: rgba(0, 0, 0, 0.4);
  border: 2px solid var(--main-color);
  color: var(--main-color);
  padding: 12px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  z-index: 1003;
  margin-bottom: 20px; /* trochę odstępu na dole */
}

.registerButton:hover {
  background: var(--main-color);
  color: #0d0d0d;
  transform: scale(1.05);
}

/* Dodatkowe reguły dla małych ekranów */
@media screen and (max-width: 600px) {
  .modalContent {
    width: 90%;
    margin: 10px;
    padding: 10px; /* zmniejszony padding na małych ekranach */
  }

  .scrollableContent {
    padding: 0 5px; /* mniej paddingu wewnątrz na małych ekranach */
  }

  .modalContent input {
    display: block;
    width: 100%;
    margin-bottom: 3px; /* mniejsze odstępy między inputami */
  }

  .registerButton {
    font-size: 1rem;
    padding: 10px 20px;
  }
}
