/* Globalny reset box-sizing */
* {
    box-sizing: border-box;
  }
  
  /* Kontener formularza kontaktowego */
  .contactContainer {
    margin: 0 auto;
    padding: 20px;
    color: var(--main-color);;
    text-align: center;
    min-height: 100vh;
    background-color: transparent;
    position: relative;
  }
  
  /* Formularz kontaktowy */
  .contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background: rgba(0, 0, 0, 0.6);
    border: 2px solid #10141A;
    padding: 20px;
    border-radius: 0px;
    max-width: 450px;
    margin: 20px auto;
  }
  
  /* Etykiety formularza */
  .contactForm label {
    width: 100%;
    font-size: 1rem;
    text-align: left;
    margin-bottom: 5px;
  }
  
  /* Pola formularza: input i textarea */
  .contactForm input,
  .contactForm textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #10141A;
    border-radius: 0px;
    background-color: #222;
    color: var(--main-color);;
    font-size: 1rem;
  }
  
  /* Przycisk wysyłania wiadomości */
  .contactForm button {
    padding: 12px 24px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    background-color: var(--accent-color); 
    color: #000;
    font-weight: bold;
    transition: background-color 0.3s, border-color 0.3s;
    border-radius: 0px;
  }
  
  .contactForm button:hover {
    background-color: #e600d0;
  }
  
  /* Wiadomość zwrotna (feedback) */
  .feedbackMessage {
    margin-top: 12px;
    font-size: 1.2rem;
    color: var(--accent-color);
  }
  
  /* Responsywność */
  @media (max-width: 480px) {
    .contactForm {
      max-width: 90%;
    }
  }
  